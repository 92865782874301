import React from "react";
import TestQuestion from "./TestQuestion";
import TestFinal from "./TestFinal";
import TestStart from "./TestStart";
import BackButton from "../common/BackButton";
import TestProgress from "./TestProgress";


class TestPage extends React.Component {

 
    constructor(props) {
        super(props);

        this.state = {
            questions: [
                {
                    text: '1. Вам было не очень интересно или не очень нравилось что-либо делать',
                    block: 0,
                },
                {
                    text: '2. Вы грустили, были подавлены или испытывали чувство безысходности',
                    block: 0,
                },
                {
                    text: '3. Вам было трудно заснуть, у вас был прерывистый сон, или вы слишком много спали',
                    block: 0,
                },
                {
                    text: '4. Вы были утомлены, или у вас было мало сил',
                    block: 0,
                },
                {
                    text: '5. У Вас был плохой аппетит, или вы переедали',
                    block: 0,
                },
                {
                    text: '6. Вы плохо о себе думали – вы считали себя неудачником (неудачницей) или были в себе разочарованы, или считали, что подвели свою семью',
                    block: 0,
                },
                {
                    text: '7. Вам было трудно сосредоточиться, например, на чтении газеты или на просмотре телепередач',
                    block: 0,
                },
                {
                    text: '8. Вы двигались или говорили настолько медленно, что окружающие могли бы это заметить? Или наоборот, вы были настолько суетливы или взбудоражены, что передвигались гораздо больше обычного',
                    block: 0,
                },
                {
                    text: '9. Вас посещали мысли о том, что Вам лучше было бы умереть, или о том, чтобы причинить себе какой-нибудь вред',
                    block: 0,
                },
                {
                    text: '10. Вы сильно нервничали, тревожились или беспокоились о чем-то',
                    block: 1,
                },
                {
                    text: '11. Вы были неспособны справиться с волнением и успокоиться',
                    block: 1,
                },
                {
                    text: '12. Вы сильно волновались по разным поводам',
                    block: 1,
                },
                {
                    text: '13. Вам было трудно расслабиться',
                    block: 1,
                },
                {
                    text: '14. Вы были настолько неспокойны, что Вам было тяжело усидеть на месте',
                    block: 1,
                },
                {
                    text: '15. Вы легко злились и раздражались',
                    block: 1,
                },
                {
                    text: '16. Вы испытывали страх, словно должно произойти что-то плохое',
                    block: 1,
                },
                {
                    text: '17. Как часто вы чувствуете себя измотанным?',
                    block: 2,
                },
                {
                    text: '18. Как часто вы испытываете физическое истощение?',
                    block: 2,
                },
                {
                    text: '19. Как часто вы эмоционально истощены?',
                    block: 2,
                },
                {
                    text: '20. Как часто вы чувствуете себя слабым и подверженным болезням?',
                    block: 2,
                },
                {
                    text: '21. Как часто вы чувствуете усталость?',
                    block: 2,
                },
                {
                    text: '22. Как часто вы думаете: «Я больше не могу»?',
                    block: 2,
                },
                {
                    text: '23. Вы выматываетесь по утрам при мысли о том, что вам предстоит еще один рабочий день?',
                    block: 2,
                },
                {
                    text: '24. Чувствуете ли вы, что каждый рабочий час для вас утомителен?',
                    block: 2,
                },
            ],
            currentQuestion: 0,
            results: [
                0,
                0,
                0
            ]
        }
    }


    onSelect = (index) => {
        let result = this.state.results;
        let currentQuestion = this.state.currentQuestion;
        currentQuestion++;

        result[this.state.questions[this.state.currentQuestion].block] += index;

        if (currentQuestion === this.state.questions.length)
            this.props.onComplete(result)
        else
            this.setState({currentQuestion: this.state.currentQuestion + 1, result: result});
    }

    render() {

        return <div className="container">
            <div className='diary-header'>
                <BackButton />
                <h4>Клиническое тестирование</h4>
                <div className='question-block'>
                    <img src='/img/icons/question-black.svg'/>
                </div>
            </div>

            <TestProgress currentPercent={parseInt(this.state.currentQuestion / this.state.questions.length * 100)}/>

{/*
            <div className="test-progressbar">
                <div className="test-progressbar-text">Прогресс 33%</div>
                <div className="test-progress">
                    <div className="test-progress-behind"></div>
                    <div style={{width: '33%'}} className="test-progress-active"></div>
                </div>
            </div>
    */}
            <TestQuestion 
                title={this.state.questions[this.state.currentQuestion].text}
                options={['Ни разу', 'Несколько дней', 'Более половины дней', 'Почти каждый день']}
                onSelect={(index) => this.onSelect(index)}
            />
    

            {/*<TestStart />*/}
        </div>;
    }
}

export default TestPage;