import React from "react";
import FaqItem from "./FaqItem";


class FaqBlock extends React.Component {



    getPaymentBlock = () => {
        return [
            {
                title: <span>Смогу ли я прервать программу?</span>,
                content: <span>Конечно. Если вы ещё не начали программу и не посетили первую консультацию, вернём всю сумму. <br/><br/>Если терапия уже началась, придётся оплатить прошедшие дни со старта программы - но мы вернём деньги за оставшиеся недели!</span>
            },
            {
                title: <span>Как оплатить?</span>,
                content: <span>Есть 2 варианта оплаты:
                <ul>
                    <li style={{marginBottom: '1rem'}}>Оплатить программу одним платежом</li>
                    <li>Оплатить <u>долями</u>. <b>Сумма покупки будет разделена на 4 равные части.</b> Первый платёж вы осуществите сразу. Последующие каждые 2 недели</li>
                </ul>
                </span>
            },
            {
                title: <span>Что такое <b>”Долями”</b>?</span>,
                content: <span><b>«Долями»</b> — это <b>сервис от «Тинькофф Банка», который позволит начать терапию сразу, а оплатить её позже частями</b><br/><br/>

                Сумма покупки разделится на <u>4 части</u>: первый платёж вы осуществите сразу (25%), последующие каждые 2 недели.
                То есть фактически сформируется <b><u>беспроцентная рассрочка</u></b> на 6 недель.
                <br/><br/>
                Оплатить можно с карты любого банка
                <br/><br/>
                <b><u>Это не кредит,</u></b> а просто способ оплаты.  Дополнительно ничего делать не нужно, не надо заполнять анкету и ждать рассмотрения.</span>
            },
            {
                title: <span>Как скоро после оплаты смогу начать терапию?</span>,
                content: <span>После оплаты мы проведем глубокий анализ вашего эмоционального и физического состояния и предложим список из 5-8 свободных психотерапевтов, с которыми <b>вы сможете начать терапию в течение ближайших 24-48 часов</b></span>
            },
            {
                title: <span>Защищен ли мой платеж?</span>,
                content: <span>Оплата проходит через провайдера платежей и абсолютно безопасна для вас. Платежи защищены.
                <br/><br/>
                <u><i>Uwio не хранит и не собирает данные карт пользователей.</i></u></span>
            },
            {
                title: <span>Как пройдёт моя терапия?</span>,
                content: <span>
                    Терапия начнётся с нескольких важных шагов:
                    <br/><br/>

                    <b>1. Пройдёте клиническое тестирование и выберете психотерапевта</b><br/>
                    Проведем глубокий анализ эмоционального и физического состояния и предложим список из 5-8 свободных психотерапевтов, с которыми вы сможете начать терапию в течение ближайших <b>24-48</b> часов
                    <br/><br/>

                    <b>2. Посетите первую сессию</b><br/>
                    Специалист задаст несколько вопросов, окажет поддержку, установит первопричину проблемы. Затем вы вместе наметите план психотерапии и её конечную цель
                    <br/><br/>

                    <b>3. Подбор индивидуальной программы</b><br/>
                    Психотерапевт подберёт <b>индивидуальную программу</b> для решения конкретно вашей проблемы, исходя из:
                    <ul>
                        <li>Результатов клинического тестирования</li>
                        <li>Специфики вашего запроса</li>
                        <li>Информации, полученной на первой консультации</li>
                    </ul>
                    <br/><br/>

                    <b>4. Следуйте индивидуальной программе терапии</b><br/>
                    Посещайте консультации с психотерапевтом каждые <b>7-10 дней</b>, а между ними общайтесь с вашим специалистом и куратором в чате, проходите самостоятельно уроки и практики, работайте с инструментами, следите за прогрессом в системе аналитики
                    <br/><br/>
                    
                    <b>5. Достигайте долгосрочных изменений</b><br/>
                    Программа <b>Uwio</b> поможет вам решить текущие проблемы и запросы.<br/>
                    Но мы также осознаём, что трудности являются частью вашей жизни. Поэтому мы даём навыки, инструменты и стратегии для решения любых проблем, с которыми вы можете столкнуться в будущем. 

                </span>
            },
            {
                title: <span>Почему программа терапии разделена на блоки?</span>,
                content: <span>
                    Мы разделили программу на блоки, чтобы вам было легче начать терапию.
                    <br/><br/>
                    Каждый блок имеет свою ценность, при этом программа терапии не меняется в зависимости от варианта прохождения.
                    <br/><br/>
                    Полная программа подойдёт тем, кто готов сразу пройти весь терапевтический цикл, <b>при этом стоимость 1 недели будет на 20% ниже.</b>
                </span>
            },
            {
                title: <span>Сколько длится терапия?</span>,
                content: <span>
                    <b>Полная программа терапии длится 8 недель.</b><br/> Она разделена на 3 блока:
                    <ul>
                        <li>Блок А - 3 недели</li>
                        <li>Блок В - 3 недели</li>
                        <li>Блок С - 2 недели</li>
                    </ul>
                </span>
            },
            {
                title: <span>Почему нет разовых консультаций?</span>,
                content: <span>
                    Психоэмоциональные проблемы особенно такие как: тревожные расстройства, депрессия и выгорание годами формировались на основе негативного опыта, установок и убеждений, поэтому <b>для качественной терапии необходим минимальный, адекватный по времени цикл занятий</b>, при этом мы стремимся не затягивать терапию на долгие месяцы и годы.
                    <br/><br/>
                    В UWIO четко структурированные, последовательные и ограниченные по времени программы, которые приведут к конкретным изменениям.
                </span>
            },
            {
                title: <span>Останется ли у меня доступ к программе?</span>,
                content: <span>
                    <b>Да</b>, вся пройденная программа, инструменты аналитики останутся <b>в вашем доступе навсегда!</b>
                </span>
            },
        ]
    }

    getAboutPsyBlock = () => {
        return [
            {
                title: <span>Профессиональный фокус и подготовка специалистов Uwio</span>,
                content: <div>
                    <b>Психотерапевты Uwio</b> - это специалисты, имеющие большой опыт работы с тревожными расстройствами, депрессией и выгоранием
                    <br/><br/>
                    <b>Как мы отбираем психотерапевтов:</b><br/>
                    <ul>
                        <li style={{marginBottom: '1rem'}}><b>Академическая подготовка</b><br/><div style={{marginLeft: '-30px'}}>Проверяем наличие профильного психологического или медицинского образования</div></li>
                        <li style={{marginBottom: '1rem'}}><b>Профессиональный фокус</b><br/><div style={{marginLeft: '-30px'}}>Анализируем успешные кейсы специалистов в работе с эмоциональными нарушениями и знания об устройстве психики.</div></li>
                        <li style={{marginBottom: '1rem'}}><b>Работают в КПТ подходах</b><br/><div style={{marginLeft: '-30px'}}>Психотерапевты Uwio используют методы когнитивно-поведенческой терапии. КПТ является оптимальным стандартом при терапии тревожных расстройств, депрессии и выгорания. Подход одобрен ВОЗ</div></li>
                        <li><b>Соблюдают этические принципы</b><br/><div style={{marginLeft: '-30px'}}>Специалисты Uwio сохраняют полную конфиденциальность, не оценивают, не осуждают и ничего не навязывают</div></li>
                    </ul>

                </div>
            },
            {
                title: <span>Смогу ли выбрать психотерапевта?</span>,
                content: <span>
                    <b>Да</b>, после прохождения клинического тестирования мы назначим вам <b>5-8 свободных специалистов</b>.
                    Все они компетентны и имеют четкий фокус в работе с тревожными расстройствами, депрессией и выгоранием.
                    <br/><br/>
                    <b>Из них вы выберете того, кто вам интуитивно нравится больше и подходит по доступному времени консультаций</b>
                </span>
            },
            {
                title: <span>Что будет на консультациях с психотерапевтом?</span>,
                content: <span>
                    <b>Специалист выявит причину вашей проблемы</b>, поможет проработать внутренние убеждения, изменить негативные установки и паттерны поведения.
                    <br/><br/>
                    <b>Психотерапевт будет сопровождать вас</b> на протяжении всей программы, отслеживать ваши изменения, вносить корректировки при необходимости с целью достижения качественных и долговременных изменений
                    <br/><br/>
                    <i>Все психотерапевты Uwio работают в КПТ подходах, рекомендованными ВОЗ для терапии эмоциональных проблем.</i>
                </span>
            },
            {
                title: <span>Как часто встречаться со специалистом?</span>,
                content: <span>
                    В рамках программы вы будете посещать консультации с психотерапевтом раз в 7-10 дней
                </span>
            },
            {
                title: <span>Как психотерапевт сможет анализировать моё состояние между консультациями?</span>,
                content: <span>
                    В приложении доступны терапевтические инструменты, которые помогут развивать навыки самоанализа и повышать осознанность.
                    <br/><br/>
                    <b>Информация из инструментов отправляется напрямую вашему специалисту.</b> Благодаря этому он видит все ваши трудности и изменения, происходящие между сессиями, и в случае необходимости придёт на помощь в чате.
                    <br/><br/>
                    А еще это позволит проводить консультации более содержательно, конструктивно и результативно, сокращая время "непродуктивного и пустого диалога".
                </span>
            },
            {
                title: <span>Смогу ли связаться с терапевтом в промежутке между сессиями?</span>,
                content: <span>Да, в программу включен чат со специалистом между сессиями</span>
            },
            {
                title: <span>Смогу ли сменить психотерапевта</span>,
                content: <span>
                    Да, в случае необходимости в личном кабинете вы сможете сменить психотерапевта.
                    <br/><br/>
                    Данная опция включена в стоимость программы.
                </span>
            },
        ]
    }

    getAboutConsultationsBlock = () => {
        return [
            {
                title: <span>Как будут проходить консультации?</span>,
                content: <span>
                    Консультации будут проходить <b>в онлайн формате</b> прямо внутри Веб-приложения.
                    <br/><br/>
                    Это удобно и доступно. Вы можете посещать консультации прямо из своего дома, офиса или любого другого места, где у вас есть доступ к интернету и устройство для видеозвонков или чата.
                </span>
            },
            {
                title: <span>Мой диалог с психологом конфиденциален?</span>,
                content: <span>
                    Да. Всё сказанное на сессии – только между вами и вашим психотерапевтом. 
                    <br/><br/>
                    Более того, видеосвязь между вами и терапевтом осуществляется напрямую в телеграмм, и у нас, и кого-либо другого нет даже технической возможности что-либо увидеть или услышать.
                </span>
            },
            {
                title: <span>Как подготовиться к первой сессии?</span>,
                content: <span>
                    <b>В специальной подготовке нет необходимости.</b> Но если у вас есть время и возможность, можете обдумать 3 вопроса - это поможет вам лучше понять свои цели и ожидания от психотерапии: 
                    <br/>
                    <ol>
                        <li>На какие сферы вашей жизни негативно влияют ваши эмоции, мысли, поведение?</li>
                        <li>Что вы хотели бы изменить в себе?</li>
                        <li>К какому результату вы хотели бы прийти после прохождения программы терапии?</li>
                    </ol>
                </span>
            },
            {
                title: <span>Смогу ли отменить/перенести консультацию?</span>,
                content: <span>
                    Да, конечно.
                    <br/><br/>
                    Отменить или перенести сессию вы можете в вашем личном кабинете в приложении на панели управления сессиями.
                </span>
            },
            {
                title: <span>Пропадут ли мои консультации, если я не использую их во время прохождения одного блока?</span>,
                content: <span>
                    Если вы не успеете посетить все консультации за время одного блока терапии, вы можете использовать эти консультации при прохождении следующего блока терапии или после окончания программы.
                </span>
            },
            {
                title: <span>Смогу ли докупить консультации?</span>,
                content: <span>
                    Да.<br/>
                    Вы сможете докупить консультации, если захотите дополнительно проработать свой запрос с психотерапевтом. 
                </span>
            },
            {
                title: <span>Смогу ли при желании продолжить посещать консультации с психотерапевтом после прохождения программы?</span>,
                content: <span>
                    Да, вы сможете продолжить посещение консультаций у специалиста.
                    <br/><br/>
                    Дополнительно их можно приобрести в личном кабинете!
                </span>
            }
        ]
    }


    getAboutTherapy = () => {
        return [
            {
                title: <span>С какими проблемами вы работаете?</span>,
                content: <span>
                    UWIO поможет решить вам следующие проблемы:
                    <ul>
                        <li>Выгорание</li>
                        <li>Депрессия</li>
                        <li>Нарушение сна</li>
                        <li>Невроз</li>
                        <li>Повышенная тревожность</li>
                        <li>Панические атаки</li>
                        <li>Генерализованное тревожное расстройство (ГТР)</li>
                        <li>Социальная тревога</li>
                        <li>Фобии и страхи</li>
                        <li>Обсессивно-компульсивное расстройство (ОКР)</li>
                    </ul>
                </span>
            },
            {
                title: <span>Мой диалог с психологом конфиденциален?</span>,
                content: <span>
                    <b>Да. Всё сказанное на сессии – только между вами и вашим психотерапевтом.</b>
                    <br/><br/>
                    Более того, видеосвязь между вами и терапевтом осуществляется напрямую в телеграмм, и у нас, и кого-либо другого нет даже технической возможности что-либо увидеть или услышать.
                </span>
            },
            {
                title: <span>Чем терапия в UWIO отличается от традиционной терапии?</span>,
                content: <div>
                    UWIO - это клиническая программа терапии, доступ к которой можно получить со смартфона в Web-приложении телеграмм.
                    <br/><br/>
                    <div style={{position: 'relative'}}>
                        <div style={{position: 'absolute', left: '-8px', top: 0, height: '100%', borderLeft: '3px solid #E26833B0'}}></div>
                        Программа включает в себя регулярные консультации с психотерапевтом, интерактивные уроки и практики, чат со специалистом, терапевтические инструменты и систему аналитики с обратной связью от терапевта.
                    </div>
                    <br/>
                    <div style={{position: 'relative'}}>
                        <div style={{position: 'absolute', left: '-8px', top: 0, height: '100%', borderLeft: '3px solid #E26833B0'}}></div>
                        В отличие от традиционной индивидуальной терапии, UWIO предоставляет полную и постоянную поддержку на протяжении всей программы: в приложении регулярно появляются новые упражнения и задания, которые вы можете выполнять в своем темпе прямо в телефоне.
                    </div>
                    <br/>
                    <i>Вам также доступны консультации с психотерапевтом в любое время в рамках программы.</i>
                    <br/><br/>
                    <div style={{position: 'relative'}}>
                        <div style={{position: 'absolute', left: '-8px', top: 0, height: '100%', borderLeft: '3px solid #E26833B0'}}></div>
                        Ещё одно преимущество терапии в Uwio — наличие терапевтических инструментов и системы аналитики с контролем прогресса.
                        <br/><br/>
                        Результаты использования данных инструментов и вся информация поступают напрямую вашему специалисту. Благодаря этому он видит ваши изменения и трудности, с которыми вы сталкиваетесь между сессиями, и в случае необходимости придёт на помощь в чате.
                        <br/><br/>
                        Также данная информация позволяет проводить консультации более содержательно, конструктивно и результативно, сокращая время "непродуктивного и пустого диалога".
                    </div>
                    <br/>
                    <i>Все изменения, которые с вами происходят, отображаются в виде графиков и отчетов на панели аналитики. Вы точно будете знать, насколько эффективна терапия, и какие изменения с вами произошли.</i>
                </div>
            },
            {
                title: <span>Как я смогу понять, что психотерапия эффективна</span>,
                content: <span>
                    <b>В первую очередь, по собственным ощущениям.</b> Через 2-3 недели вы почувствуете, что ваше эмоциональное состояние стало меняться в лучшую сторону:

                    <ul>
                        <li>вам будет гораздо проще делать то, что вы любите</li>
                        <li>появится энергия</li>
                        <li>повысится продуктивность и улучшится самочувствие</li>
                    </ul>

                    А еще, благодаря работе с инструментами в приложении, вы начнете лучше анализировать себя и замечать те мысли, которые вызывают негативные эмоциональные реакции и поведение - это позволит вам прийти к нужным изменениям в своем состоянии и жизни.
                    <br/><br/>
                    Также для более глубоко анализа вы и психолог будет использовать специально разработанные аналитические протоколы, которые помогут детальнее оценить ваши изменения и внести корректировки в процесс психотерапии, если они будут необходимы. <i>Все изменения будут отображаться в виде графиков в системе аналитики.</i>
                </span>
            },
            {
                title: <span>Почему у Вас программа, а не отдельные консультации?</span>,
                content: <span>
                    Психоэмоциональные проблемы особенно такие как: тревога, депрессия и выгорание годами формировались на основе негативного опыта, установок и убеждений, поэтому для <b>качественной терапии необходим минимальный, адекватный по времени цикл занятий</b>, при этом мы стремимся не затягивать терапию на долгие месяцы и годы.
                    <br/><br/>
                    В UWIO четко структурированные, последовательные и ограниченные по времени программы, которые приведут к конкретным изменениям.
                </span>
            },
            {
                title: <span>Что такое формат непрерывной терапии?</span>,
                content: <span>
                    <b>Непрерывная модель терапии означает, что мы с вами всегда.</b>
                    <br/><br/>
                    В традиционной психотерапии основная работа проводится во время сессий с психологом, при этом между консультациями практически ничего не происходит - это снижает эффективность терапии и увеличивает ее длительность.
                    <br/><br/>
                    <b>Мы решаем эту проблему!</b>
                    <br/><br/>
                    Сессии с психотерапевтом сочетаются с программой интерактивных уроков и практик, поддержкой куратора между встречами, неограниченным чатом со специалистом и аналитикой прогресса.
                </span>
            },
            {
                title: <span>Почему для успешной терапии необходим комплексный подход?</span>,
                content: <span>
                    Наши привычки, поведение и образ жизни, в том числе и то, <b>как мы едим и как мы спим</b>, являются <b>следствием</b> нашего образа мышления - когнитивного фактора ментального и общего здоровья, <b>с которым будет работать ваш психотерапевт</b>.
                    <br/><br/>
                    Задача специалиста помочь вам сделать ваше мышление, реакции на обстоятельства и внутренние установки оптимальными, преодолеть все трудности и негативные переживания на пути к глобальным изменениям. В то же время персонализированная программа и инструменты в приложении <b>Uwio</b> в течение нескольких недель <b>помогут правильно и последовательно изменить ваш образ жизни</b> через формирование оптимальных паттернов мышления, привычек адекватного сна и питания, главных драйверов здоровой физиологии.
                </span>
            },
            {
                title: <span>Что такое терапия основанная на данных?</span>,
                content: <span>
                    <b>UWIO - это терапия основанная на данных</b>
                    <br/><br/>
                    В приложении доступны терапевтические инструменты, которые помогут развивать навыки самоанализа и повышать осознанность.
                    <b>Информация из инструментов отправляется напрямую вашему специалисту.</b> Благодаря этому он видит все ваши трудности и изменения, происходящие между сессиями, и в случае необходимости придёт на помощь в чате.
                    А еще это позволяет проводить консультации более содержательно, конструктивно и результативно, сокращая время "непродуктивного и пустого диалога".
                    <br/><br/>
                    <b>Прозрачный анализ прогресса</b>
                    <br/><br/>
                    Все изменения, которые с вами происходят, отображаются в виде графиков и отчетов на панели аналитики. Вы точно будете знать, насколько эффективна терапия, и какие изменения с вами произошли.
                </span>
            },
            {
                title: <span>Будет ли терапия комфортной для меня?</span>,
                content: <span>
                    В <b>UWIO</b> всё реализовано таким образом, чтобы Вы пришли к изменениям максимально быстро и просто
                    <ol>
                        <li style={{marginBottom: '1rem'}}>
                            <b>Удобство</b><br/>
                            <div style={{marginLeft: '-20px'}}>Все материалы о Вашей проблеме будут приходить в комфортном для вас темпе, чтобы вы могли на практике применить полученные знания</div>
                        </li>
                        <li style={{marginBottom: '1rem'}}>
                            <b>Простота изучения</b><br/>
                            <div style={{marginLeft: '-20px'}}>На интерактивные уроки и практики вы будете тратить не более 15 минут в день.</div>
                        </li>
                        <li style={{marginBottom: '1rem'}}>
                            <b>Всё в одном приложении</b><br/>
                            <div style={{marginLeft: '-20px'}}>В приложении собраны все терапевтические инструменты и форматы помощи, которые необходимы для успешных и качественных,   а что самое главное измеримых изменений</div>
                        </li>
                        <li>
                            <b>Полная конфиденциальность</b><br/>
                            <div style={{marginLeft: '-20px'}}>Ваша информация защищена на 100% и не передается третьим лицам. Только вы и психолог.<br/><i>Специалисты Uwio подписывают соответствующее соглашение.</i></div>
                        </li>
                    </ol>
                </span>
            },
        ];
    }



    getAllInOne = () => {
        return [
            {
                title: <span>Что такое приложение All in One</span>,
                content: <span>
                    В приложении собраны все терапевтические инструменты и форматы помощи, которые необходимы для успешных и качественных, а что самое главное измеримых изменений.
                </span>
            },
            {
                title: <span>В чем суть персонализированной программы?</span>,
                content: <span>
                    <b>Программа</b> имеет четкую структуру и <b>подбирается</b> вашим психотерапевтом <b>специально под вас</b> на основе клинического тестирования и первой консультации.
                    <br/><br/>
                    Задача программы дополнить консультации с психотерапевтом, сформировать и внедрить новые модели мышления и поведения в вашу повседневную жизнь, чтобы сделать результаты устойчивыми и снизить вероятность рецидивов в дальнейшем.
                </span>
            },
            {
                title: <span>В чем ценность системы аналитики?</span>,
                content: <span>
                    <b>UWIO - это терапия основанная на данных</b>
                    <br/><br/>
                    В приложении доступны терапевтические инструменты, которые помогут развивать навыки самоанализа и повышать осознанность.
                    <b>Информация из инструментов отправляется напрямую вашему специалисту.</b> Благодаря этому он видит все ваши трудности и изменения, происходящие между сессиями и в случае необходимости придёт на помощь в чате.
                    <br/><br/>
                    А еще это позволяет проводить консультации более содержательно, конструктивно и результативно, сокращая время "непродуктивного и пустого диалога".
                    <br/><br/>
                    <b>Прозрачный анализ прогресса</b><br/>
                    Все изменения, которые с вами происходят, отображаются в виде графиков и отчетов на панели аналитики. Вы точно будете знать, насколько эффективна терапия и какие изменения с вами произошли.
                </span>
            },
            {
                title: <span>Какие терапевтические инструменты терапии доступны в приложении?</span>,
                content: <span>
                    <ul>
                        <li>
                            <b>Сканер состояния</b><br/>
                            Инструмент, позволяющий отслеживать качество сна и другие важные параметры эмоционального и физического состояния
                        </li>
                        <li>
                            <b>Дневник рефлексии</b><br/>
                            Инструмент, основанный на когнитивно-поведенческой терапии. Дневник поможет вам лучше анализировать себя и свои реакции на события и триггеры.
                            С его помощью вы и ваш специалист увидите скрытые убеждения и установки, негативно влияющие на вас.
                        </li>
                        <li>
                            <b>Таск-трекер</b><br/>
                            Ваш виртуальный помощник, который Отправит урок, напомнит о выполнении практики или предстоящей консультации с психотерапевтом
                        </li>
                    </ul>
                </span>
            },
            {
                title: <span>Как психотерапевт сможет оценить моё состояние между консультациями?</span>,
                content: <span>
                    В приложении доступны терапевтические инструменты, которые помогут развивать навыки самоанализа и повышать осознанность.
                    <br/><br/>
                    <b>Информация из инструментов отправляется напрямую вашему специалисту.</b> Благодаря этому он видит все ваши трудности и изменения, происходящие между сессиями, и в случае необходимости придёт на помощь в чате.
                    <br/><br/>
                    А еще это позволяет проводить консультации более содержательно, конструктивно и результативно, сокращая время "непродуктивного и пустого диалога".
                </span>
            },
            {
                title: <span>Будет ли терапия комфортной для меня?</span>,
                content: <span>
                    В <b>UWIO</b> всё реализовано таким образом, чтобы Вы пришли к изменениям максимально быстро и просто
                    <ol>
                        <li style={{marginBottom: '1rem'}}>
                            <b>Удобство</b><br/>
                            <div style={{marginLeft: '-20px'}}>Все материалы о Вашей проблеме будут приходить в комфортном для вас темпе, чтобы вы могли на практике применить полученные знания</div>
                        </li>
                        <li style={{marginBottom: '1rem'}}>
                            <b>Простота изучения</b><br/>
                            <div style={{marginLeft: '-20px'}}>На интерактивные уроки и практики вы будете тратить не более 15 минут в день.</div>
                        </li>
                        <li style={{marginBottom: '1rem'}}>
                            <b>Всё в одном приложении</b><br/>
                            <div style={{marginLeft: '-20px'}}>В приложении собраны все терапевтические инструменты и форматы помощи, которые необходимы для успешных и качественных,   а что самое главное измеримых изменений</div>
                        </li>
                        <li>
                            <b>Полная конфиденциальность</b><br/>
                            <div style={{marginLeft: '-20px'}}>Ваша информация защищена на 100% и не передается третьим лицам. Только вы и психолог.<br/><i>Специалисты Uwio подписывают соответствующее соглашение.</i></div>
                        </li>
                    </ol>
                </span>
            },
            {
                title: <span>Где находится приложение All in One</span>,
                content: <span>
                    Приложение All in One находится <b>внутри телеграмм</b> и функционирует через Web-App - встроенное в телеграмм бот Web-приложение
                </span>
            },
            {
                title: <span>Что такое Web App?</span>,
                content: <span>
                    <b>Web App (TWA)</b> — это встроенное в мессенджер web-приложение, созданное на базе чат-бота.
                    <br/><br/>
                    Оно работает с помощью встроенного в Telegram браузера и открывается поверх интерфейса сервиса. По сути, <b>это полноценное приложение внутри Telegram</b>.
                </span>
            }
        ]
    }


    getCommonQuestions = () => {
        return [
            {
                title: <span>Могу ли пользоваться возможностями приложения на компьютере?</span>,
                content: <span>
                    Да, конечно.<br/>
                    Вы можете использовать приложение Uwio на вашем персональном компьютере.
                </span>
            },
            {
                title: <span>Клиническое тестирование</span>,
                content: <span>
                    С помощью клинического тестирования мы проводим глубокий анализ вашего эмоционального и физического состояния.
                    <br/><br/>
                    На основании результатов тестирования мы предложим <b>список из 5-8 психотерапевтов</b>, с которыми вы сможете начать терапию в течение ближайших <b>24-48 часов</b>.
                </span>
            },
            {
                title: <span>Что такое когнитивно-поведенческая терапия (КПТ)?</span>,
                content: <span>
                    <b>Когнитивно-поведенческая терапия (КПТ)</b> – это метод психологической терапии, который сосредоточен на взаимосвязи между мыслями, эмоциями и поведением.
                    <br/><br/>
                    Подход одобрен ВОЗ и является золотым стандартом терапии при тревожных расстройствах, депрессии и выгорании.
                </span>
            },
        ]
    }


    render() {

        return <div ref={this.props.refProp} className="faq-block" style={{padding: '20px'}}>
            <div className="advantage-header" style={{marginBottom: '20px'}}>Отвечаем на вопросы</div>

            <div>
                <FaqItem icon={'/img/icons/wallet.svg'} title={'Блок "Тарифы и оплата"'} content={this.getPaymentBlock()}/>
                <FaqItem icon={'/img/icons/think.svg'} title={'О психотерапевтах Uwio'} content={this.getAboutPsyBlock()}/>
                <FaqItem icon={'/img/icons/together.svg'} title={'О консультациях'} content={this.getAboutConsultationsBlock()}/>
                <FaqItem icon={'/img/icons/give.svg'} title={'О терапии Uwio'} content={this.getAboutTherapy()}/>
                <FaqItem icon={'/img/icons/phone.svg'} title={'О возможностях приложения All in One'} content={this.getAllInOne()}/>
                <FaqItem icon={'/img/icons/faq-question.svg'} title={'Общие вопросы'} content={this.getCommonQuestions()}/>
            </div>
        </div>;
    }
}

export default FaqBlock;