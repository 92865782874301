import React from "react";


class TestProgress extends React.Component {


    render() {


        return <div className="test-progress-container">
            <div className="test-progress-indicator">Прогресс {this.props.currentPercent}%</div>
            <div className="test-progress">
                <div className="test-progress-bar"></div>
                <div className="test-progress-bar" style={{background: '#3A767B', width: this.props.currentPercent + '%'}}></div>
            </div>
        </div>;
    }
}


export default TestProgress;