import React from "react";
import PaymentSlide from "./PaymentSlide";

import '../css/payment.css';
import PaymentAdvantage from "./PaymentAdvantage";
import FaqBlock from "./FaqBlock";


class PaymentPage extends React.Component {

    constructor (props) {
        super(props);
        this.programBlock = React.createRef(); 
        this.programFull = React.createRef();

        this.slider = React.createRef();

        this.questionsRef = React.createRef();

        this.state = {
            scrollState: 0
        }
    }

    componentDidMount() {
        document.body.style = 'background: #E7F3F4;';
        window.Telegram.WebApp.expand();

        let url = new URL(window.location.href)
        let query = new URLSearchParams(url.search);
        let anchor = query.get('questions');
        if (anchor)
        {
            this.questionsRef.current.scrollIntoView();   
        }
    }



    payWidget = () => {

        function decodeToken (token) {
            var base64Url = token.split('.')[1];
            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));

            return JSON.parse(jsonPayload);
        }

        let result = decodeToken(localStorage.getItem('token'));

        console.log(result)

        var widget = new window.cp.CloudPayments();
        widget.pay('charge', // или 'charge'
            { //options
                publicId: 'pk_fc4a2c2b11763311e816f257f3031', //id из личного кабинета
                description: 'Привязка карты', //назначение
                amount: 10, //сумма
                currency: 'RUB', //валюта
                accountId: result.id, //идентификатор плательщика (необязательно)
                //invoiceId: '1234567', //номер заказа  (необязательно)
                //email: 'user@example.com', //email плательщика (необязательно)
                skin: "mini", //дизайн виджета (необязательно)
                autoClose: 3, //время в секундах до авто-закрытия виджета (необязательный)
                data: {
                    type: 0, // Card Privyazka
                    //bookId: this.state.id 
                }
            },
            {
                onSuccess: () => { window.Telegram.WebApp.close(); },
                onFail: function (reason, options) { // fail
                    //действие при неуспешной оплате
                },
                onComplete: function (paymentResult, options) { //Вызывается как только виджет получает от api.cloudpayments ответ с результатом транзакции.
                    //например вызов вашей аналитики Facebook Pixel
                }
            }
        )
    }



    scrollIntoView = (element, targetPos) => {

        let step = element.scrollWidth / 50;
        if (targetPos === 0)
            step *= -1


        let previousScroll = -1;
        let interval = setInterval(() => {

                if (previousScroll == element.scrollLeft)
                {
                    clearInterval(interval)
                    this.setState({stop: false})
                }
                else
                {
                    previousScroll = element.scrollLeft

                    element.scrollLeft += step;
                }

        }, 8)

    }

    scroll = (e) => {


        if (this.state.stop)
        {
            this.setState({scrollState: e.currentTarget.scrollLeft})
            return;
        }

        if (this.state.scrollState < e.currentTarget.scrollLeft)
        {
            this.setState({scrollState: e.currentTarget.scrollLeft, stop: true}, () =>  this.scrollIntoView(this.slider.current, this.slider.current.scrollWidth));
            
        }
        else if (this.state.scrollState > e.currentTarget.scrollLeft)
        {
            this.setState({scrollState: e.currentTarget.scrollLeft, stop: true}, () => this.scrollIntoView(this.slider.current, 0));
        }
    }

    render() {

        return <div >
            <div className="payment-header" style={{marginLeft: '8px', marginRight: '8px', marginTop: '8px'}}>
                <img width={65} height={21} src="/img/logo.svg" />
            </div>
            
            <PaymentAdvantage />

            <div className="advantage-header" style={{marginBottom: '20px'}}>
                Тарифы и оплата
            </div>

            <div ref={this.slider} onScroll={e => this.scroll(e)} className="payment-slider" style={{marginLeft: '8px', marginBottom: '48px'}}>
                <PaymentSlide 
                    refProp={this.programBlock} 
                    title={<>БЛОК <span style={{color: '#E26833'}}>“A”</span></>}
                    content={<>Установите и поймёте первопричину проблемы. Придете к первым изменениям и снизите выраженность симптомов.<br/>
                    Освоите навыки самоанализа и эмоциональной регуляции.<br/>
                    Повысите качество сна.</>}
                    items={
                        [
                            '3 консультации с психотерапевтом',
                            'Чат с психотерапевтом между сессиями',
                            'Программа развития оптимальных моделей мышления и поведения при тревожных расстройствах, депрессии и выгорании',
                            'Программа глубокой оптимизации сна',
                            'Поддержка куратора',
                            'Аналитика прогресса',
                            'Терапевтические инструменты',
                            'Регулярные отчеты о состоянии с инсайтами',
                            'Приложение All in One'
                        ]
                    }
                    totalPrice={'13 500'}
                    weekPrice={'4 500'}
                    splitPrice={'3 375'}
                    duration={'3 недели'}
                    
                    buy={this.payWidget}
                    buySplit={this.payWidget}
                />
                <PaymentSlide 
                    refProp={this.programFull}
                    title={<><span style={{color: '#E26833'}}>Полная</span> программа</>}
                    content={<>Нормализуете эмоциональное и физическое состояние. Поднимите уровень энергии и продуктивности. Оптимизируете сон и питание.
                        Освоите оптимальные модели мышления, сформируете продуктивные привычки <br/>и паттерны поведения.</>}
                    items={
                        [
                            '6 консультации с психотерапевтом',
                            'Чат с психотерапевтом между сессиями',
                            'Программа развития оптимальных моделей мышления и поведения при тревожных расстройствах, депрессии и выгорании',
                            'Программа глубокой оптимизации сна',
                            'Коррекция рациона и психологических\n аспектов пищевого поведения для общего\n и ментального здоровья',
                            'Программа освоения рациональных установок для снижения риска рецидивов',
                            'Поддержка куратора',
                            'Аналитика прогресса',
                            'Терапевтические инструменты',
                            'Регулярные отчеты о состоянии с инсайтами',
                            'Приложение All in One',
                            'Пожизненный доступ к программам терапии'
                        ]
                    }
                    totalPrice={'28 800'}
                    weekPrice={'3 600'}
                    splitPrice={'7 200'}
                    duration={'8 недель'}

                    buy={this.payWidget}
                    buySplit={this.payWidget}
                />
            </div>
            

            <FaqBlock refProp={this.questionsRef} />
        </div>;
    }
}

export default PaymentPage;