import React from "react";
import BackButton from "../common/BackButton";


class AnalysisPage extends React.Component {


    render() {

        return <div>

            <div className='diary-header'>
                <BackButton />
                <h4>Панель аналитики</h4>
                <div className='question-block'>
                </div>
            </div>

            <div className="inner-menu">
                <a className="inner-menu-item inner-menu-active">
                    <img src="/img/icons/graph-up.svg" alt=""/>
                    <span>Аналитика</span>
                </a>

                


                <a className="inner-menu-item psy-item" style={{background: '#3A767B'}}>
                    <div>
                        <img className="psy-img" src="/img/psy-example.png" />
                    </div>
                    <div className="psy-watch">
                        <img className="psy-watch-icon" width={18} height={18} src="/img/icons/eye.svg" />
                        <span>Ваш психолог</span>
                    </div>
                </a>
            </div>

            <div style={{display: 'flex', flexFlow: 'column', gap: '20px'}}>
                <img src="/img/graph1.svg" />
                <img src="/img/graph2.svg" />
                <img src="/img/graph3.svg" />
                <img src="/img/graph4.svg" />
                <img src="/img/graph5.svg" />
            </div>


        </div>;
    }
}


export default AnalysisPage;