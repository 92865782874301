import React from "react";
import TestResult from "./TestResult";


class TestResultContainer extends React.Component {

    burnoutLevel = (val) => {
        if (val <= 5)
            return 'минимальное';
        if (val <= 11)
            return 'умеренное';
        if (val <= 17)
            return 'среднее';
        return 'тяжелое'
    }

    anxietyLevel = (val) => {
        if (val <= 4)
            return 'минимальная';
        if (val <= 9)
            return 'умеренная';
        if (val <= 14)
            return 'средняя';
        return 'тяжелая'
    }

    depressionLevel = (val) => {
        if (val <= 4)
            return 'минимальная';
        if (val <= 9)
            return 'легкая'
        if (val <= 14)
            return 'умеренная';
        if (val <= 19)
            return 'тяжелая';
        return 'крайне тяжелая'
    }

    render() {

        return <div className="test-result-container">
                <TestResult title={'Тревога: ' + this.anxietyLevel(this.props.anxietyLevel)} rate={this.props.anxietyLevel + '/21'} targetOffset={'50px'} cursorOffset={this.props.anxietyLevel / 21 * 330}/>
                <TestResult title={'Депрессия: ' + this.depressionLevel(this.props.depressionLevel)} rate={this.props.depressionLevel + '/27'} targetOffset={'50px'} cursorOffset={this.props.depressionLevel / 27 * 330}/>
                <TestResult title={'Выгорание: ' + this.burnoutLevel(this.props.burnoutLevel)} rate={this.props.burnoutLevel + '/24'} targetOffset={'50px'} cursorOffset={this.props.burnoutLevel / 24 * 330}/>
            </div>;
    }
}

export default TestResultContainer;