import React from "react";
import TestResult from "./TestResult";


class TestFinal extends React.Component {


    burnoutLevel = (val) => {
        if (val <= 5)
            return 'минимальное';
        if (val <= 11)
            return 'умеренное';
        if (val <= 17)
            return 'среднее';
        return 'тяжелое'
    }

    anxietyLevel = (val) => {
        if (val <= 4)
            return 'минимальная';
        if (val <= 9)
            return 'умеренная';
        if (val <= 14)
            return 'средняя';
        return 'тяжелая'
    }

    depressionLevel = (val) => {
        if (val <= 4)
            return 'минимальная';
        if (val <= 9)
            return 'легкая'
        if (val <= 14)
            return 'умеренная';
        if (val <= 19)
            return 'тяжелая';
        return 'крайне тяжелая'
    }



    render() {

        return <div className="container">
            <div className="test-final-header">
                Ваши показатели тревожности, депрессии и выгорания по результатам тестирования
            </div>

            <div className="test-result-container">
                <TestResult title={'Тревога: ' + this.anxietyLevel(this.props.result[1])} rate={this.props.result[1] + '/21'} targetOffset={'50px'} cursorOffset={this.props.result[1] / 21 * 330}/>
                <TestResult title={'Депрессия: ' + this.depressionLevel(this.props.result[0])} rate={this.props.result[0] + '/27'} targetOffset={'50px'} cursorOffset={this.props.result[0] / 27 * 330}/>
                <TestResult title={'Выгорание: ' + this.burnoutLevel(this.props.result[2])} rate={this.props.result[2] + '/24'} targetOffset={'50px'} cursorOffset={this.props.result[2] / 24 * 330}/>
            </div>

            <div onClick={() => window.Telegram.WebApp.close()} className="test-continue-button">
                Продолжить
                <img src='/img/icons/arrow-forward.svg' />
            </div>

        </div>;
    }
}

export default TestFinal;