import React from "react";
import FaqInnerItem from "./FaqInnerItem";


class FaqItem extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            closed: true
        }
    }

    render() {

        return <div>
            <div className="faq-header" onClick={() => this.setState({closed: !this.state.closed})} >
                <div className="faq-header-content">
                    <img width={24} height={24} src={this.props.icon} />
                    {this.props.title}
                </div>
                
                <img 
                    className="faq-open-close" 
                    src={this.state.closed ? '/img/icons/arrow-top.svg' : '/img/icons/arrow-bottom.svg'} 
                />
            </div>

            {!this.state.closed ?
            <div>
                {this.props.content?.map((item, index) => <FaqInnerItem key={index} title={item.title} content={item.content} />)}
            </div>
            :
            undefined
            }
        </div>;
    }
}

export default FaqItem;