import React from "react";
import Rate from "./Rate";
import TimeBlock from "./TimeBlock";
import IconButton from "./IconButton";
import TextButton from "./TextButton";
import WellnessMenu from "./WellnessMenu";
import ColorizedIconButton from "./ColorizedIconButton";
import DatePicker from "../common/DatePicker";
import MessageModal from "../common/MessageModal";
import TargetsModal from "./targets/TargetsModal";
import BackButton from "../common/BackButton";
import connection from "../scripts/connection";


class WellnessPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            survey: {
                recordDate: new Date(),
                mood: null,
                asleepTime: '23:00',
                awakeTime: '07:00',

                asleepStatus: null,
                asleepStatusComment: null,
                
                awakeCount: null,
                awakeCountComment: null,

                awakeStatus: null,
                awakeStatusComment: null,

                physicalStatus: null,
                energyStatus: null,
                anxietyStatus: null,
                satisfactionStatus: null,
                
                food: null,

                activity: null
            }
        }
    }

    componentDidMount() {
        this.allowTextareasToDynamicallyResize();
    }

    allowTextareasToDynamicallyResize = () =>  {
        let textareas = document.getElementsByTagName('textarea');
        for (let i = 0; i < textareas.length; i++) {
          textareas[i].addEventListener('input', (e) => {
            e.target.style.height = "5px";
            e.target.style.height = (e.target.scrollHeight) + "px";
          });
        }
    }

    send = () => {

        let clientId = localStorage.getItem('clientId');
        let payload = JSON.parse(JSON.stringify(this.state.survey));

        payload.asleepTime += ':00';
        payload.awakeTime += ':00';
        payload.awakeStatus = payload.awakeStatus?.toString();
        payload.asleepStatus = payload.asleepStatus?.toString();
        payload.awakeCount = payload.awakeCount?.toString();

        console.log(payload)


        connection.AuthorizePost('/clients/' + clientId + '/wellness', 
        payload,
        (success) => {
            console.log('Отправлено')
            this.setState({message: 'Данные отправлены',
            survey: {
                recordDate: new Date(),
                mood: null,
                asleepTime: '23:00',
                awakeTime: '07:00',

                asleepStatus: null,
                asleepStatusComment: null,
                
                awakeCount: null,
                awakeCountComment: null,

                awakeStatus: null,
                awakeStatusComment: null,

                physicalStatus: null,
                energyStatus: null,
                anxietyStatus: null,
                satisfactionStatus: null,
                
                food: null,

                activity: null
            }
            });
        },
        (error) => {
            this.setState({message: 'Произошла ошибка, попробуйте позже'})
        },
        (fatalError) => {
            this.setState({message: 'Произошла ошибка, попробуйте позже'})
        }
        );
    }


    updateMood = (mood) => {
        let survey = this.state.survey;
        survey.mood = mood;

        this.setState({survey: survey});
    }

    selectAsleepTime = (time) => {
        let survey = this.state.survey;
        survey.asleepTime = time;

        this.setState({survey: survey});
    }

    selectAwakeTime = (time) => {
        let survey = this.state.survey;
        survey.awakeTime = time;

        this.setState({survey: survey});
    }

    onPhysicalStatusChange = (val) => {
        let survey = this.state.survey;
        survey.physicalStatus = val;

        this.setState({survey: survey});
    }


    onEnergyStatusChange = (val) => {
        let survey = this.state.survey;
        survey.energyStatus = val;

        this.setState({survey: survey});
    }

    onAnxietyStatusChange = (val) => {
        let survey = this.state.survey;
        survey.anxietyStatus = val;

        this.setState({survey: survey});
    }

    onSatisfactionStatusChange = (val) => {
        let survey = this.state.survey;
        survey.satisfactionStatus = val;

        this.setState({survey: survey});
    }

    onFoodChange = (val) => {
        let survey = this.state.survey;
        survey.food = val;

        this.setState({survey: survey});
    }

    onActivityChange = (val) => {
        let survey = this.state.survey;
        survey.activity = val;

        this.setState({survey: survey});
    }

    onNightAwakeChange = (val) => {
        let survey = this.state.survey;
        survey.awakeCount = val;

        this.setState({survey: survey});
    }

    onNightAwakeCommentChange = (comment) => {
        let survey = this.state.survey;
        survey.awakeCountComment = comment;

        this.setState({survey: survey});
    }

    onAsleepStatusChange = (val) => {
        let survey = this.state.survey;
        survey.asleepStatus = val;

        this.setState({survey: survey});
    }

    onAsleepStatusCommentChange = (comment) => {
        let survey = this.state.survey;
        survey.asleepStatusComment = comment;

        this.setState({survey: survey});
    }

    onAwakeStatusChange = (val) => {
        let survey = this.state.survey;
        survey.awakeStatus = val;

        this.setState({survey: survey});
    }

    onAwakeStatusCommentChange = (comment) => {
        let survey = this.state.survey;
        survey.awakeStatusComment = comment;

        this.setState({survey: survey});
    }


    onDateSelect = (date) => {
        if (date > new Date())
            return;

        let survey = this.state.survey;
        survey.recordDate = date;

        this.setState({survey: survey, showCalendar: false});
    }


    formatDate = (date, $1, $2, $3, separator='') => {
        const dateObj = {
            date: String(date.getDate()).padStart(2, '0'),
            month: String(date.getMonth() + 1).padStart(2, '0'),
            year: date.getFullYear()
        };

        return dateObj[$1] + separator + dateObj[$2] + separator + dateObj[$3];
    }

    render() {

        return (
        <div className="container">
            
            {this.state.showPsy ? 
                <MessageModal text={'Психолог регулярно изучает ваши данные'} onClose={() => this.setState({showPsy: false})} />
            :
                undefined
            }

            {this.state.message ?
                <MessageModal text={this.state.message} onClose={() => this.setState({message: null})}/>
            :
                undefined
            }

            <div className='diary-header'>
                <BackButton />
                <div onClick={() => this.setState({showTargets: !this.state.showTargets})}>
                    <img className="wellness-target-button"  src='/img/icons/icon-1.svg'/>
                    <div className="wellness-target-description">
                        Цели
                    </div>
                </div>
                <div className='question-block'>
                    <img src='/img/icons/question-black.svg'/>
                </div>
            </div>

            {this.state.showTargets ?
                <TargetsModal onClose={() => this.setState({showTargets: false})}/>
            :
                undefined
            }

            <h4 style={{textAlign: 'center'}}>Сканер состояния</h4>

            <WellnessMenu page={1} onPsyClick={() => this.setState({showPsy: true})}/>

            <div id="diary-content">
            

                <div className="wellness-date-selector">
                    <div>
                        Дата записи
                    </div>
                    <div onClick={() => this.setState({showCalendar: !this.state.showCalendar})} className="wellness-record-date">
                        {this.formatDate(this.state.survey.recordDate, 'date', 'month', 'year', '.')}
                    </div>
                </div>

                {this.state.showCalendar ?
                <DatePicker onSelect={(date) => this.onDateSelect(date)}/>
                :
                undefined
                }


                <div className="wellness-block wellness-block-black">
                    <div className="wellness-block-header">
                        <span>Как оцените сегодняшний день?</span>
                    </div>
                    <div className="awake-line">
                        <ColorizedIconButton onClick={() => this.updateMood('verybad')} img={'img/icons/emotions/very-sad.svg'} activeImg={'img/icons/emotions/very-sad-active.svg'} active={this.state.survey.mood === 'verybad'} title={'Ужасно'} fill={'#6F7B79'} color={'#6F7B79'}/>
                        <ColorizedIconButton onClick={() => this.updateMood('bad')} img={'img/icons/emotions/sad.svg'} activeImg={'img/icons/emotions/sad-active.svg'} active={this.state.survey.mood === 'bad'} title={'Плохо'} fill={'#2F78D4'} color={'#2F78D4'}/>
                        <ColorizedIconButton onClick={() => this.updateMood('neutral')} img={'img/icons/emotions/neutral.svg'} activeImg={'img/icons/emotions/neutral-active.svg'} active={this.state.survey.mood === 'neutral'} title={'Так себе'} fill={'#A63EC7'} color={'#A63EC7'}/>
                        <ColorizedIconButton onClick={() => this.updateMood('good')} img={'img/icons/emotions/nice.svg'} activeImg={'img/icons/emotions/nice-active.svg'} active={this.state.survey.mood === 'good'} title={'Хорошо'} fill={'#2FC061'} color={'#2FC061'}/>
                        <ColorizedIconButton onClick={() => this.updateMood('happy')} img={'img/icons/emotions/happy.svg'} activeImg={'img/icons/emotions/happy-active.svg'} active={this.state.survey.mood === 'happy'} title={'Отлично'} fill={'#F88D01'} color={'#F88D01'}/>
                    </div>
                </div>

                <div className="wellness-blocks-container">
                    <h4>Качество сна</h4>
                        

                    <TimeBlock title={'Время отхода ко сну прошлой ночью'} selectedTime={this.state.survey.asleepTime} startTime={'18:00'} onSelect={(time) => this.selectAsleepTime(time)}/>

                    <TimeBlock title={'Время пробуждения'} selectedTime={this.state.survey.awakeTime} startTime={'04:00'} onSelect={(time) => this.selectAwakeTime(time)}/>

                    {this.state.pro ?
                    <div className="wellness-block">
                        <div className="wellness-block-header">
                            <span>Как долго засыпали?</span>
                        </div>
                            <div className="awake-line">
                                <IconButton img={'img/icons/asleep-fast.svg'} activeImg={'img/icons/asleep-fast-active.svg'} active={this.state.survey.asleepStatus === 1} title={'Быстро'} onClick={() => this.onAsleepStatusChange(1)}/>
                                <IconButton img={'img/icons/asleep-mid.svg'} activeImg={'img/icons/asleep-mid-active.svg'} active={this.state.survey.asleepStatus === 2} title={'Средне'} onClick={() => this.onAsleepStatusChange(2)}/>
                                <IconButton img={'img/icons/asleep-mid.svg'} activeImg={'img/icons/asleep-mid-active.svg'} active={this.state.survey.asleepStatus === 3} title={'Долго'} onClick={() => this.onAsleepStatusChange(3)}/>
                                <IconButton img={'img/icons/asleep-long.svg'} activeImg={'img/icons/asleep-long-active.svg'} active={this.state.survey.asleepStatus === 4} title={'Очень долго'} onClick={() => this.onAsleepStatusChange(4)}/>
                            </div>
                            <div className="text-field">
                                <textarea onChange={(e) => this.onAsleepStatusCommentChange(e.target.value)} className="additional" type="text" placeholder="О проблеме засыпания (макс. 300 символов)" ></textarea>
                            </div>
                        
                    </div>
                    :
                    undefined 
                    }

                    {this.state.pro ?
                    <div id="block2" className="wellness-block">
                        <div className="wellness-block-header">
                            <span>Сколько раз просыпались ночью?</span>
                        </div>
                        
                        <div className="awake-line">
                            <TextButton value={'0'} active={this.state.survey.awakeCount === 0} onClick={() => this.onNightAwakeChange(0)}/>
                            <TextButton value={'1'} active={this.state.survey.awakeCount === 1} onClick={() => this.onNightAwakeChange(1)}/>
                            <TextButton value={'2'} active={this.state.survey.awakeCount === 2} onClick={() => this.onNightAwakeChange(2)}/>
                            <TextButton value={'3'} active={this.state.survey.awakeCount === 3} onClick={() => this.onNightAwakeChange(3)}/>
                            <TextButton value={'4'} active={this.state.survey.awakeCount === 4} onClick={() => this.onNightAwakeChange(4)}/>
                            <TextButton value={'5<'} active={this.state.survey.awakeCount === 5} onClick={() => this.onNightAwakeChange(5)}/>
                        </div>
                        <div className="text-field">
                            <textarea value={this.state.survey.awakeCountComment ?? ''} onChange={(e) => this.onNightAwakeCommentChange(e.target.value)} className="additional" type="text" placeholder="Причина просыпания (макс. 500 символов)" ></textarea>
                        </div>
                    
                    </div>
                    :
                    undefined
                    }

                    <div className="wellness-block">
                        <div className="wellness-block-header">
                            <span>Как чувствовали себя после пробуждения?</span>
                        </div>
                        <div className="wellness-block-content awake-line">
                            <IconButton img={'img/icons/awake-ideal.svg'} activeImg={'img/icons/awake-ideal-active.svg'} active={this.state.survey.awakeStatus === 1} title={'Идеально'} onClick={() => this.onAwakeStatusChange(1)}/>
                            <IconButton img={'img/icons/awake-well.svg'} activeImg={'img/icons/awake-well-active.svg'} active={this.state.survey.awakeStatus === 2} title={'Хорошо'} onClick={() => this.onAwakeStatusChange(2)}/>
                            <IconButton img={'img/icons/awake-mid.svg'} activeImg={'img/icons/awake-mid-active.svg'} active={this.state.survey.awakeStatus === 3} title={'Средне'} onClick={() => this.onAwakeStatusChange(3)}/>
                            <IconButton img={'img/icons/awake-bad.svg'} activeImg={'img/icons/awake-bad-active.svg'} active={this.state.survey.awakeStatus === 4} title={'Плохо'} onClick={() => this.onAwakeStatusChange(4)}/>
                        </div>
                        <div className="text-field">
                            <textarea onChange={(e) => this.onAwakeStatusCommentChange(e.target.value)} className="additional" type="text" placeholder="Комментарий (макс. 300 символов)" ></textarea>
                        </div>
                    </div>

                    {this.state.pro ?
                    <div onClick={() => this.setState({pro: false})} className="pro-button">
                        Свернуть
                    </div>
                    :
                    <div onClick={() => this.setState({pro: true})} className="pro-button">
                        PRO
                    </div>
                    }

                </div>

                <div className="wellness-blocks-container">

                    <div className="wellness-block">
                        <div className="wellness-block-header">
                            <span>Физическое состояние</span>
                        </div>
                        <Rate value={this.state.survey.physicalStatus} onSelect={this.onPhysicalStatusChange}/>
                    </div>

                    <div className="wellness-block">
                        <div className="wellness-block-header">
                            <span>Ваш уровень энергии</span>
                        </div>
                        <Rate value={this.state.survey.energyStatus} onSelect={this.onEnergyStatusChange}/>
                    </div>

                    <div className="wellness-block">
                        <div className="wellness-block-header">
                            <span>Сегодняшний уровень тревожности</span>
                        </div>
                        <Rate value={this.state.survey.anxietyStatus} onSelect={this.onAnxietyStatusChange}/>
                    </div>


                    <div className="wellness-block">
                        <div className="wellness-block-header">
                            <span>Насколько Вы сегодня довольны</span>
                        </div>
                        <Rate value={this.state.survey.satisfactionStatus} onSelect={this.onSatisfactionStatusChange}/>
                    </div>

                    <div className="wellness-block">
                        <div className="wellness-block-header">
                            <span>Что Вы сегодня ели и пили?</span>
                        </div>
                        <div className="wellness-block-content text-field">
                            <textarea value={this.state.survey.food ?? ''} onChange={(e) => this.onFoodChange(e.target.value)} type="text" placeholder="Напишите, что ели/пили" ></textarea>
                        </div>
                    </div>

                    <div className="wellness-block">
                        <div className="wellness-block-header">
                            <span>Что Вы делали сегодня?</span>
                        </div>
                        <div className="wellness-block-content text-field">
                            <textarea value={this.state.survey.activity ?? ''} onChange={(e) => this.onActivityChange(e.target.value)} type="text" placeholder="Напишите, что делали сегодня"></textarea>
                        </div>
                    </div>

                </div>

                <div className="block-content end-block">
                    <button onClick={this.send} className="complete-button">Сохранить и отправить</button>
                    <div className="error-message">Для сохранения необходимо оценить уровень тревожности</div>
                </div>
            </div>
        </div>);
    }
}


export default WellnessPage;