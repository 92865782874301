import React from "react";


class PaymentAdvantage extends React.Component {


    
    render() {

        return <div className="advantage-block" style={{marginLeft: '8px', marginRight: '8px'}}>
            <div className="advantage-header">Преимущества терапии в<br/> Uwio</div>
            
            <table style={{width: '100%', marginTop: '-17px'}}>
                <tbody>
                <tr style={{width: '100%'}}>
                    <td className="advantage-title" style={{width: '160px'}}></td>
                    <td style={{width: '24%', position: 'relative'}}>
                        <div className="advantage-table-column-header">UWIO</div>
                    </td>
                    <td style={{width: '24%', position: 'relative'}}>
                        <div className="advantage-table-column-header advantage-table-second-col">Другие платформы<br/>и частные<br/> специалисты</div>
                    </td>
                </tr>
                </tbody>
            </table>
            <div style={{borderRadius: '8px', overflow: 'hidden'}}>
                
                <table className="advantage-table" style={{width: '100%'}} >
                    <tbody>
                    <tr className="advantage-white-row" style={{width: '100%'}}>
                        <td className="advantage-title" style={{width: '160px'}}>Специализация на тревожных расстройствах, депрессии и выгорании</td>
                        <td className="advatange-icon-column advantage-central-column" style={{width: '24%'}}><img width={17} height={17} src="/img/icons/done.svg" /></td>
                        <td className="advatange-icon-column" style={{width: '24%'}}><img width={17} height={17} src="/img/icons/not.svg" /></td>
                    </tr>
                    <tr className="advantage-colored-row">
                        <td className="advantage-title">Возможность выбрать специалиста</td>
                        <td className="advatange-icon-column advantage-central-column advantage-central-column-colorized"><img width={17} height={17} src="/img/icons/done.svg" /></td>
                        <td className="advatange-icon-column"><img width={17} height={17} src="/img/icons/done.svg" /></td>
                    </tr>
                    <tr className="advantage-white-row">
                        <td className="advantage-title">Видео-сессии с психотерапевтом</td>
                        <td className="advatange-icon-column advantage-central-column"><img width={17} height={17} src="/img/icons/done.svg" /></td>
                        <td className="advatange-icon-column"><img width={17} height={17} src="/img/icons/done.svg" /></td>
                    </tr>
                    <tr className="advantage-colored-row">
                        <td className="advantage-title">Поддержка специалиста<br/> в чате между сессиями</td>
                        <td className="advatange-icon-column advantage-central-column advantage-central-column-colorized"><img width={17} height={17} src="/img/icons/done.svg" /></td>
                        <td className="advatange-icon-column"><img width={17} height={17} src="/img/icons/not.svg" /></td>
                    </tr>
                    <tr className="advantage-white-row">
                        <td className="advantage-title">Персонализированная программа из уроков и практик</td>
                        <td className="advatange-icon-column advantage-central-column"><img width={17} height={17} src="/img/icons/done.svg" /></td>
                        <td className="advatange-icon-column"><img width={17} height={17} src="/img/icons/not.svg" /></td>
                    </tr>
                    <tr className="advantage-colored-row">
                        <td className="advantage-title">Система аналитики и контроля прогресса</td>
                        <td className="advatange-icon-column advantage-central-column advantage-central-column-colorized"><img width={17} height={17} src="/img/icons/done.svg" /></td>
                        <td className="advatange-icon-column"><img width={17} height={17} src="/img/icons/not.svg" /></td>
                    </tr>
                    <tr className="advantage-white-row">
                        <td className="advantage-title">Оптимизация сна и питания</td>
                        <td className="advatange-icon-column advantage-central-column"><img width={17} height={17} src="/img/icons/done.svg" /></td>
                        <td className="advatange-icon-column"><img width={17} height={17} src="/img/icons/not.svg" /></td>
                    </tr>
                    <tr className="advantage-colored-row">
                        <td className="advantage-title">Приложение со всеми необходимыми инструментами терапии</td>
                        <td className="advatange-icon-column advantage-central-column advantage-central-column-colorized"><img width={17} height={17} src="/img/icons/done.svg" /></td>
                        <td className="advatange-icon-column"><img width={17} height={17} src="/img/icons/not.svg" /></td>
                    </tr>
                    <tr className="advantage-white-row">
                        <td className="advantage-title">Длительность терапии</td>
                        <td className="advatange-icon-column advantage-central-column" style={{fontWeight: '400'}}>8 недель</td>
                        <td className="advatange-icon-column" style={{fontWeight: '400'}}>Нет четких<br/> рамок</td>
                    </tr>
                    <tr>
                        <td className="advantage-title last-row-col">Стоимость</td>
                        <td style={{border: 0, position: 'relative'}} className="advatange-icon-column"><div className="col-central-rounded" style={{fontWeight: '400'}}><span style={{fontWeight: '500'}}>От 3 600 ₽</span>в неделю</div></td>
                        <td className="advatange-icon-column last-row-col" style={{whiteSpace: 'nowrap', fontSize: '9px', fontWeight: '400'}}><span style={{fontWeight: '500'}}>3 000 - 6 000 ₽</span><br/>за консультацию</td>
                    </tr>
                    </tbody>
                </table> 
            </div>

        </div>;
    }
}


export default PaymentAdvantage;