import React from "react";


class PaymentSlide extends React.Component {


    render() {

        return <div ref={this.props.refProp} className="payment-slide">
            <div className="payment-main">
                <div className="title-block">
                    <div className="title">{this.props.title}</div>
                    <div className="title-time">
                        <img className="title-time-icon" src="img/icons/clock-grey.svg" />
                        {this.props.duration}
                    </div>
                </div>
                <div className="card-text">
                    {this.props.content}
                </div>

                <div className="payment-gurantee">*Гарантия возврата средств</div>
                <div className="payment-buttons">
                    <div className="payment-buy" onClick={this.props.buy}>
                        <div className="payment-buy-common">Общая стоимость {this.props.totalPrice} ₽</div>
                        <div className="payment-buy-price">{this.props.weekPrice} ₽ в неделю</div>
                    </div>
                    <div className="payment-split" onClick={this.props.buySplit}>
                        <div className="payment-dolyami">
                            <img style={{padding: '2px 6px'}} width={55} height={11} src="/img/dolyami.svg" />
                        </div>
                        <div>4 платежа по {this.props.splitPrice} ₽</div>
                    </div>
                </div>
            </div>

            <div className="payment-faq">
                <div className="payment-faq-line">
                    
                </div>
                <div className="payment-faq-items">
                    {this.props.items.map((item, index) => <div key={index} className="payment-faq-element">{item}</div>)}
                </div>
            </div>

        </div>;
    }
}

export default PaymentSlide;