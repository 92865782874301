import React from "react";
import MenuButton from "./MenuButton";
import ProgressBar from "./ProgressBar";
import MenuExternalButton from "./MenuExternalButton";


class MenuPage extends React.Component {



    render() {
        return <div className="container">


            <div className='diary-header'>
                <img src='/img/icons/arrow-back.svg'/>
                <h4>Меню</h4>
                <div className='question-block' style={{background: 'transparent'}}>
                </div>
            </div>

            <ProgressBar />

            <div className="menu-block">
                <MenuButton icon={'wellness.svg'} url={'/wellness'} text={'Сканер состояния'}/>
                <MenuButton icon={'diary-menu.svg'} url={'/diary'} text={'Дневник рефлексии'}/>
                <MenuExternalButton icon={'psy1.svg'} url={'https://t.me/tgroom?token=' + localStorage.getItem('token')} text={'Консультация с психологом'}/>
                <MenuButton icon={'task.svg'} url={'/tasktracker'} text={'Таск трекер'}/>
                <MenuButton icon={'plan.svg'} url={'/plan'} text={'План программы'}/>
                <MenuButton icon={'menu-test.svg'} url={'/test'} text={'Тесты'}/>
                <MenuExternalButton icon={'support.svg'} url={'https://t.me/uwio_help'} text={'Поддержка'}/>
            </div>
        </div>
    }
}


export default MenuPage;