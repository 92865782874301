import React from "react";
import TestResult from "./TestResult";
import BackButton from "../common/BackButton";
import connection from "../scripts/connection";
import TestResultContainer from "./TestResultContainer";


class TestPreview extends React.Component {


    constructor(props) {
        super(props);

        this.state = {


        }
    }

    componentDidMount() {

        let clientId = localStorage.getItem('clientId');

        connection.AuthorizeGet('/clients/' + clientId + '/tests/last',
            (success) => {
                if (success)
                {
                    this.setState({previousResults: success});   
                }
            },
            (error) => {
                console.log(error);
            },
            (fatal) => {
                console.log(fatal);
            }
        )

    }


    render() {

        return <div className="container">
            <div className='diary-header'>
                <BackButton />
                <h4>Клиническое тестирование</h4>
                <div className='question-block'>
                    <img src='/img/icons/question-black.svg'/>
                </div>
            </div>
            

            <div className="test-preview-header">
                <div className="test-preview-header-content">
                    <div className="test-preview-header-title">Клиническое тестирование</div>
                    <div className="test-preview-header-description">Пройдите тестирование, чтобы установить выраженность Ваших симптомов за прошедшие 2 недели</div>
                </div>
                <img src="/img/test-preview-2.png" alt=""/>
            </div>
            
            <div onClick={this.props.onTestStart} className="test-preview-button">
                <div className="test-preview-button-text">Клинический тест</div>
                <ul>
                    <li>8 минут</li>
                </ul>
                <img style={{position: 'absolute', top: '50%', right: '15px', transform: 'translate(0, -50%)'}} src="/img/icons/test-preview-icon.svg" alt=""/>
            </div>

            {this.state.previousResults ?
            <>
            <div className="test-preview-subtitle">Ваши показатели тревожности, депрессии и выгорания по результатам предыдущего тестирования</div>
            <TestResultContainer anxietyLevel={this.state.previousResults.anxietyLevel} depressionLevel={this.state.previousResults.depressionLevel} burnoutLevel={this.state.previousResults.burnoutLevel}/>
            </>
            :
            undefined
            }
        </div>;
    }
}

export default TestPreview;