import React from 'react';
import { BrowserRouter, Navigate, Route, Router, Routes } from 'react-router-dom';
import DiaryPage from './diary/DiaryPage';
import TasktrackerPage from './tasktracker/TasktrackerPage';
import TestPage from './test/TestPage';
import TestPreview from './test/TestPreview';
import WellnessPage from './wellness/WellnessPage';
import MenuPage from './menu/MenuPage';
import TestOnboardingPage from './test/TestOnboardingPage';
import PaymentPage from './payment/PaymentPage';
import TestFinal from './test/TestFinal';
import TestDefaultPage from './test/TestDefaultPage';
import AnalysisPage from './analysis/AnalysisPage';

class App extends React.Component {

  constructor(props) {
    super(props);
  }

  parseJwt = (token) => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }

  componentDidMount() {

    let url = new URL(window.location.href)
    let query = new URLSearchParams(url.search);
    let token = query.get('token');

    let userId = this.parseJwt(token).id;

    localStorage.setItem('token', token);
    localStorage.setItem('clientId', userId);

    window.Telegram.WebApp.expand();
  }

  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route path={"/test"}  element={<TestDefaultPage />}/>
          <Route path={"/test-ongoing"}  element={<TestOnboardingPage />}/>
          <Route path={"/diary"}  element={<DiaryPage />}/>
          <Route path={"/wellness"}  element={<WellnessPage />}/>
          <Route path={"/tasktracker"}  element={<TasktrackerPage />}/>
          <Route path={"/menu"}  element={<MenuPage />}/>
          <Route path={"/analysis"}  element={<AnalysisPage />}/>
          <Route path={"/payment"}  element={<PaymentPage />}/>
          <Route path={"*"}  element={<Navigate to="/menu" />}/>
        </Routes>
      </BrowserRouter>
    );
  }
}

export default App;
