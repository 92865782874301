import React from "react";
import TestPage from "./TestPage";
import TestFinal from "./TestFinal";
import TestStart from "./TestStart";

import connection from "../scripts/connection";


class TestOnboardingPage extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            page: 0
        }
    }


    componentDidMount() {
        window.Telegram.WebApp.expand();
    }


    testCompleted = (result) => {
        let clientId = localStorage.getItem('clientId');


        console.log('Request process');


        connection.AuthorizePost('/clients/' + clientId + '/tests',
        {
            anxietyLevel: result[1],
            depressionLevel: result[0],
            burnoutLevel: result[2]
        },
        (success) => {
            this.setState({page: 2, result: result, loading: false});
        },
        (error) => {
            console.log('Все сломалось')
        },
        (fatal) => {
            console.log('Все совсем сломалось')
        }
        )
    }

    render() {

        if (this.state.loading)
            return <div style={{textAlign: 'center'}}>Загрука...</div>

        return <>
        {this.state.page === 0 ?
            <TestStart onStart={() => this.setState({page: 1})} />
        :
            undefined    
        }

        {this.state.page === 1 ?
            <TestPage onComplete={(result) => this.setState({loading: true}, () => this.testCompleted(result))}/>
        :
            undefined    
        }

        {this.state.page === 2 ? 
            <TestFinal result={this.state.result}/>
        :
            undefined
        }
        </>;
    }
}

export default TestOnboardingPage;