import React from "react";
import { Navigate } from "react-router-dom";


class ProgressBar extends React.Component {

    constructor(props) {
        super(props);

        this.state = {


        }
    }

    render() {

        return <div className="progress-indicator">
            <div className="progress-indicator-text">
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    Вовлеченность в терапию
                    <img width={16} height={16} src='/img/icons/question-grey.svg' />
                </div>
                <div>
                    <span className="progress-actual">5</span>
                    <span className="progress-total">/7</span>
                </div>
            </div>
            {this.state.progress ?
            <Navigate to='/analysis' />
            :
            undefined
            }
            <div onClick={() => this.setState({progress: true})} className="progress-button">
                Ваш прогресс
                <img src='/img/icons/stat-up.svg' />
            </div>
        </div>;
    }
}

export default ProgressBar;