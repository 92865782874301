import React from "react";
import { Navigate } from "react-router-dom";


class MenuExternalButton extends React.Component {

    constructor(props) {
        super(props);


        this.state = {

        }
    }

    render() {

        return <a style={{textDecoration: 'none'}} href={this.props.url} >
                <div className="menu-button">
                <img width={30} height={30} className="menu-icon" src={"/img/icons/" + this.props.icon} />
                <div className="menu-text">{this.props.text}</div>
                <img className="menu-forward" src="/img/icons/arrow-forward.svg" />
            </div>
        </a>
    }
}


export default MenuExternalButton;