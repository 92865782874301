import React from "react";


class FaqInnerItem extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

            closed: true
        }
    }


    render() {

        return <div>
            <div className="faq-header" onClick={() => this.setState({closed: !this.state.closed})} >
                <div className="faq-item-title">
                    {this.props.title}
                </div>
                
                <img 
                    className="faq-open-close" 
                    src={this.state.closed ? '/img/icons/arrow-top.svg' : '/img/icons/arrow-bottom.svg'} 
                />
                {!this.state.closed ?
                <div className="faq-item-content" style={{marginTop: '12px'}}>
                    {this.props.content}
                </div>
                :
                undefined
                }
            </div>
        </div>;
    }
}

export default FaqInnerItem;